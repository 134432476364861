import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Requests from '../../../BackofficeRequests/Requests';
import { useAlerts } from '../../../context/alert';
import { useNavigate } from 'react-router';


const ChannelForm = ({ timezones }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const { showError } = useAlerts();

  const [open, setOpen] = useState(true);
  const [logoChanged, setLogoChanged] = useState("");

  const submit = async (values, { resetForm }) => {
    try {
      if (!(await Requests.instance.channel.isValidChannel(values.name, values.adReference))) {
        showError(t("channel.existingInfo"));
        return;
      }
      const { data } = await Requests.instance.channel.create(values);
      console.log("CHANNEL CREATED", data);
      const requestCampaign = await Requests.instance.campaign.createCampaign(
        "BB Service GPT",
        "AUTOMATIC-BBS-GPT",
        0,
        t('campaign.automatic.gpt.description'),
        "",
        0,
        0,
        true,
        data.advertisingScheduleIds[0],
        "automatic"
      );

      const requestCampaignPreroll = await Requests.instance.campaign.createCampaign(
        "BB Service PREROLL",
        "AUTOMATIC-BBS-PREROLL",
        0,
        t('campaign.automatic.preroll.description'),
        "",
        0,
        0,
        true,
        data.advertisingScheduleIds[0],
        "automatic"
      );
      resetForm();
      navigate(`/app/channels/edit/${data.id}`, { state: { channel: data, timezones: timezones } });
    } catch (err) {
      showError(err.message);
    }
  };

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {t('channel.addTitle')}
        </Typography>
      </AccordionSummary>
      <Divider sx={{ marginTop: 0, marginBottom: 1 }} />
      <AccordionDetails>
        <Formik
          initialValues={{
            name: '',
            adReference: '',
            logoUrl: '',
            timezone: '',
            isBroadbandy: false,
            broadbandySchedulerUrl: '',
            filterIP: ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required(t('channel.nameRequired')),
            adReference: Yup.string().max(255).required(t('channel.adRefRequired')),
            timezone: Yup.string().required(t('channel.timezoneRequired')),
            logoUrl: Yup.string().max(255),
            isBroadbandy: Yup.boolean().default(false),
            broadbandySchedulerUrl: Yup.string(),
            filterIP: Yup.string()
          })}
          validateOnChange={true}
          onSubmit={submit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('channel.name')}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.adReference && errors.adReference)}
                    fullWidth
                    helperText={touched.adReference && errors.adReference}
                    label={t('channel.adReference')}
                    name="adReference"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.adReference}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={8} xs={12} display="flex" justifyContent="center" alignItems={"center"}>
                  <TextField
                    error={Boolean(touched.logoUrl && errors.logoUrl)}
                    fullWidth
                    helperText={touched.logoUrl && errors.logoUrl}
                    label="Logo url"
                    name="logoUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="url"
                    value={values.logoUrl}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      borderColor: "rgba(189, 189, 189, 0.3)",
                      borderWidth: 1.3,
                      borderStyle: "groove",
                      height: 150,
                      width: "100%",
                      margin: "normal"
                    }}
                  >
                    {values.logoUrl && (
                      <Box
                        component="img"
                        alt="Invalid url"
                        src={values.logoUrl}
                        sx={{
                          maxHeight: '90%',
                          maxWidth: '90%',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                  </Box>
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.filterIP && errors.filterIP)}
                    fullWidth
                    helperText={touched.filterIP && errors.filterIP}
                    label="Filtro IP"
                    name="filterIP"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.filterIP}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="grouped-timezone"
                    autoHighlight={true}
                    options={timezones.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    groupLabel
                    onChange={(event, value, reason) => { setFieldValue('timezone', value.name) }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={Boolean(touched.timezone && errors.timezone)}
                        helperText={touched.timezone && errors.timezone}
                        fullWidth
                        label="Timezone"
                        name="timezone"
                        value={values.timezone}
                        variant="outlined" />}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }} justifyContent={"center"} margin={"normal"}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t('channel.addTitle')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </AccordionDetails >
    </Accordion >
  );
};

ChannelForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default ChannelForm;
