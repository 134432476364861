import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    Typography,
    TextField,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardActions
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Requests from '../../../BackofficeRequests/Requests';
import { useAlerts } from '../../../context/alert';
import { getAllTimezones } from '../../../utils/timezone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChannelServices from './ChannelServices';

const ChannelEdit = ({
    channel, ...props
}) => {
    const { t, i18n } = useTranslation();
    const [openServices, setOpenServices] = useState(false);
    const [openBasic, setOpenBasic] = useState(true);
    const [isBroadbandy, setIsBroadbandy] = useState(false);
    const [isPrerollActive, setIsPrerollActive] = useState(false);
    const [isGoogleActive, setIsGoogleActive] = useState(false);
    const [priorityBB, setPriorityBB] = useState(1);
    const [logoChanged, setLogoChanged] = useState('');
    const [tzOptions, setTzOptions] = useState([]);
    const { showError, showMessage } = useAlerts();

    const loadTimezoneOptions = () => {
        const tzones = getAllTimezones();
        let options = Object.keys(tzones).filter((tz) => {
            if (tzones[tz].countries && tzones[tz].countries.length > 0) return tzones[tz];
        });

        options = options.map((key) => {
            const firstLetter = tzones[key].name[0].toUpperCase();
            if (tzones[key].countries && tzones[key].countries.length > 0)
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...tzones[key],
                };
        });
        setTzOptions(options);
    }

    const submit = async (values) => {
        try {
            let _values = values;
            _values.isBroadbandy = isBroadbandy;
            const _channel = await Requests.instance.channel.update(channel.id, _values);
            console.log("CHANNEL UPDATE", _channel, channel);
            const campaigns = await Requests.instance.campaign.getList(1, 10, '', channel.advertisingScheduleIds[0], 'automatic')
            console.log("OBTAIN CAMPAIGNS", campaigns);
            if (values.isBroadbandy && campaigns.data.docs.length === 0) {
                const requestCampaignGPT = await Requests.instance.campaign.createCampaign(
                    "BB Service GPT",
                    "AUTOMATIC-BBS-GPT",
                    0,
                    t('campaign.automatic.gpt.description'),
                    "",
                    0,
                    0,
                    true,
                    channel.advertisingScheduleIds[0],
                    "automatic"
                );
                const requestCampaignPreroll = await Requests.instance.campaign.createCampaign(
                    "BB Service PREROLL",
                    "AUTOMATIC-BBS-PREROLL",
                    0,
                    t('campaign.automatic.preroll.description'),
                    "",
                    0,
                    0,
                    true,
                    channel.advertisingScheduleIds[0],
                    "automatic"
                );
            } else {
                let bbGpt = false;
                let bbPreroll = false;

                campaigns.data.docs.forEach(c => {
                    if (c.publisher === "AUTOMATIC-BBS-GPT") {
                        bbGpt = true;
                    }
                    if (c.publisher === "AUTOMATIC-BBS-PREROLL") {
                        bbPreroll = true;
                    }
                })

                if (!bbGpt) {
                    const requestCampaignGPT = await Requests.instance.campaign.createCampaign(
                        "BB Service GPT",
                        "AUTOMATIC-BBS-GPT",
                        0,
                        t('campaign.automatic.gpt.description'),
                        "",
                        0,
                        0,
                        true,
                        channel.advertisingScheduleIds[0],
                        "automatic"
                    );
                }
                if (!bbPreroll) {
                    const requestCampaignPreroll = await Requests.instance.campaign.createCampaign(
                        "BB Service PREROLL",
                        "AUTOMATIC-BBS-PREROLL",
                        0,
                        t('campaign.automatic.preroll.description'),
                        "",
                        0,
                        0,
                        true,
                        channel.advertisingScheduleIds[0],
                        "automatic"
                    );
                }
            }
            showMessage(t('Modifica effettuata!'));
        } catch (err) {
            showError(err.message);
        }
    };

    useEffect(async () => {
        setIsBroadbandy(channel.isBroadbandy);
        const campaigns = await Requests.instance.campaign.getList(1, 10, '', channel.advertisingScheduleIds[0], 'automatic');
    }, [channel])

    useEffect(() => {
        console.log("channle", channel);
        loadTimezoneOptions();
    }, [])

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom>
                {channel.name}
            </Typography>

            <Formik
                initialValues={{
                    name: channel.name,
                    adReference: channel.adReference,
                    logoUrl: channel.logoUrl,
                    timezone: channel.timezone,
                    isBroadbandy: channel.isBroadbandy !== undefined ? channel.isBroadbandy : false,
                    broadbandySchedulerUrl: channel.broadbandySchedulerUrl || "",
                    filterIP: channel.filterIP || ""
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required(t('channel.nameRequired')),
                    adReference: Yup.string().max(255).required(t('channel.adRefRequired')),
                    timezone: Yup.string().required(t('channel.timezoneRequired')),
                    logoUrl: Yup.string().max(255),
                    isBroadbandy: Yup.boolean().default(false),
                    filterIP: Yup.string()
                })}
                onSubmit={submit}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (

                    <form onSubmit={handleSubmit}>

                        <Card>
                            <Box>
                                <Accordion expanded={openBasic} onChange={() => setOpenBasic(!openBasic)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h4"
                                        >
                                            {t('channel.basicConfig')}
                                        </Typography>
                                    </AccordionSummary>
                                    <Divider sx={{ marginTop: 0, marginBottom: 1 }} />
                                    <AccordionDetails>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item md={12}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label={t('channel.name')}
                                                    margin="normal"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <TextField
                                                    error={Boolean(touched.adReference && errors.adReference)}
                                                    fullWidth
                                                    helperText={touched.adReference && errors.adReference}
                                                    label={t('channel.adReference')}
                                                    name="adReference"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.adReference}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item md={8} xs={12} display="flex" justifyContent="center" alignItems={"center"}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.logoUrl && errors.logoUrl)}
                                                    helperText={touched.logoUrl && errors.logoUrl}
                                                    label="Logo URL"
                                                    name="logoUrl"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.logoUrl}
                                                    placeholder={"Logo URL"}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={4}
                                                xs={12}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box
                                                    margin='normal'
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '5px',
                                                        overflow: 'hidden',
                                                        borderColor: "rgba(189, 189, 189, 0.3)",
                                                        borderWidth: 1.3,
                                                        borderStyle: "groove",
                                                        height: 150,
                                                        width: "100%",

                                                    }}
                                                >
                                                    {values.logoUrl && (
                                                        <Box
                                                            component="img"
                                                            alt="Invalid url"
                                                            src={values.logoUrl}
                                                            sx={{
                                                                maxHeight: '90%',
                                                                maxWidth: '90%',
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                            <Grid item md={6} >
                                                <TextField
                                                    error={Boolean(touched.filterIP && errors.filterIP)}
                                                    fullWidth
                                                    helperText={touched.filterIP && errors.filterIP}
                                                    label={t('channel.filterIP')}
                                                    name="filterIP"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="text"
                                                    value={values.filterIP}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item md={6}>
                                                <Autocomplete
                                                    id="grouped-timezone"
                                                    autoHighlight={true}
                                                    options={tzOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                                    groupBy={(option) => option.firstLetter}
                                                    groupLabel
                                                    onChange={(event, value, reason) => { setFieldValue('timezone', value.name); }}
                                                    getOptionLabel={(option) => { return typeof option === 'object' ? option.name : values.timezone }}
                                                    value={values.timezone}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(touched.timezone && errors.timezone)}
                                                            helperText={touched.timezone && errors.timezone}
                                                            fullWidth
                                                            label="Timezone"
                                                            name="timezone"
                                                            value={values.timezone}
                                                            variant="outlined"
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }} >
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                color="primary"
                                                size="large"
                                            >
                                                {t('channel.confirm')}
                                            </Button>
                                        </CardActions>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Card>

                        <Card sx={{ marginTop: 2 }}>
                            <Box>
                                <Accordion expanded={openServices} onChange={() => setOpenServices(!openServices)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h4"
                                        >
                                            {t('channel.servicesConfig')}
                                        </Typography>
                                    </AccordionSummary>
                                    <Divider sx={{ marginTop: 0, marginBottom: 1 }} />
                                    <Typography variant='body1' sx={{ margin: 2 }}>
                                        {t('channel.servicesInstructions')}
                                    </Typography>
                                    <AccordionDetails>
                                        <ChannelServices />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Card>


                    </form >
                )
                }
            </Formik >
        </React.Fragment >
    );
};

export default ChannelEdit;
