import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import CampaignDetail from './views/campaign/CampaignDetail';
import Campaigns from './views/campaign/Campaigns';
import ChannelListView from './views/channel/ChannelListView';
import ChannelEditView from './views/channel/ChannelEditView';
import AdUnitsView from './views/creative';
import CreateCreative from './views/creative/CreateCreative';
import CreativeDetail from './views/creative/CreativeDetail';
import NotFoundView from './views/errors/NotFoundView';
import Dashboard from './views/reports/DashboardView';
import ScheduleList from './views/schedule/ScheduleList';
import Wizard from './views/wizard';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'channels', element: <ChannelListView /> },
      { path: 'channels/edit/:id', element: <ChannelEditView /> },
      { path: 'creatives', element: <AdUnitsView /> },
      { path: 'creatives/new', element: <CreateCreative /> },
      { path: 'creatives/:id', element: <CreativeDetail /> },
      { path: 'wizard', element: <Wizard wizard /> },
      { path: 'campaigns/new', element: <Wizard /> },
      { path: 'schedules', element: <ScheduleList /> },
      { path: 'campaigns', element: <Campaigns /> },
      { path: 'campaigns/:id', element: <CampaignDetail /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
];

export default routes;
