import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Page from '../../../components/Page';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import ChannelEdit from './ChannelEdit'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}));

const ChannelEditView = () => {
    const classes = useStyles();
    const location = useLocation();
    const { channel } = location.state || {};
    const { t, i18n } = useTranslation();

    return (
        <Page className={classes.root} title={t('channel.editChannelTitle')}>
            <Container>
                <ChannelEdit channel={channel} />
            </Container>
        </Page>
    );
};

export default ChannelEditView;
