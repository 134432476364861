export const mockData = [
    {
        type: "Roll",
        name: "IMA",
        isActive: true,
        config: { "urlVast": "http://www.vast.com", "disableOn": "true", "countdownLabel": "label" }
    },
    {
        type: "Roll",
        name: "Smartclip",
        isActive: false,
        config: { "headUnit": "unit", "pollingInterval": "1000" }
    },
    {
        type: "Display",
        name: "GPT",
        isActive: true,
        config: { "urlVast": "http://www.vastgpt.com", "disableOn": "true", "countdownLabel": "label" }
    },
    {
        type: "Roll",
        name: "FreeWheel",
        isActive: true,
        config: { "headUnit": "unit", "pollingInterval": "1000" }
    },
    {
        type: "Display",
        name: "Smartclip",
        isActive: false,
        config: { "urlVast": "http://www.smartclipvast.com", "disableOn": "true", "countdownLabel": "label" }
    }
];
